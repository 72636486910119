export const TAG_TAX_DEDUCTION = 'tax deduction'
export const TAG_PUBLIC = 'public'
export const TAG_PATIENT_ACCOUNT = 'patient account'

export const tagsList = [
  TAG_TAX_DEDUCTION,
  TAG_PUBLIC,
  TAG_PATIENT_ACCOUNT,
]

export const CLINIC_LICENSE = 'clinic-license'
export const TYPE_FOR_INDIVIDUALS = 'for-individuals'
export const TYPE_FOR_LEGAL_ENTITIES = 'for-legal-entities'
export const TYPE_PARTNERS = 'partners'
export const OTHER = 'other'

export const typesMeta = {
  [CLINIC_LICENSE]: {
    name: 'Лицензия клиники'
  },
  [TYPE_FOR_INDIVIDUALS]: {
    name: 'Для физических лиц'
  },
  [TYPE_FOR_LEGAL_ENTITIES]: {
    name: 'Для юридических лиц'
  },
  [TYPE_PARTNERS]: {
    name: 'Партнерские клиники'
  },
  [OTHER]: {
    name: 'Другое'
  }
}

export const FILE_ITEM_TYPE_FILE = 'file'
export const FILE_ITEM_TYPE_LINK = 'link'
export const FILE_ITEM_TYPES = [ FILE_ITEM_TYPE_FILE, FILE_ITEM_TYPE_LINK ]
