import BaseService from './baseService'
import {
  tagsList,
  typesMeta,
  FILE_ITEM_TYPE_FILE,
  FILE_ITEM_TYPE_LINK,
} from '../consts/document'

export class FileItem {
  constructor (options) {
    this.type = options?.type
    this.startDate = options?.startDate
    this.endDate = options?.endDate
    this.file = options?.file
    this.link = options?.link
  }

  get type () {
    return this._type
  }

  set type (type) {
    this._type = type
    if (type === FILE_ITEM_TYPE_LINK) {
      this.file = null
    }
    if (type === FILE_ITEM_TYPE_FILE) {
      this.link = null
    }
  }

  toJSON () {
    const data = {
      ...this,
      type: this._type
    }
    delete data._type
    return data
  }
}

class DocumentsService extends BaseService {
  get url () {
    return 'documents'
  }

  get tags () {
    return tagsList
  }

  get types () {
    return Object.entries(typesMeta).map(([type, meta]) => ({
      type,
      ...meta
    }))
  }

  createModel () {
    return {
      id: null,
      name: null,
      code: null,
      createdAt: null,
      updatedAt: null,
      type: null,
      clinicIds: [],
      fileItems: [],
      tags: []
    }
  }

  toDto (item) {
    return {
      name: item.name,
      type: item.type,
      code: item.code,
      clinicIds: item.clinicIds,
      tags: item.tags || [],
      fileItems: item.fileItems.map(({ endDate, startDate, file, type, link }) => ({
        endDate,
        startDate,
        fileId: file?.id,
        type,
        link,
      })),
    }
  }
}

export default new DocumentsService()

